.map-container{
    width: 100%;
    height: 450px;
    position: relative;
}

.map-container>iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

  @media (max-width: 768px) {
    .map-container {
      height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .map-container {
      height: 200px;
    }
  }
  