.home_field_item {
    /* border: 1px solid rgb(0, 255, 238); */
}

.home_custom_h1__title {
    font-size: 7vw;
    font-weight: 600;
    line-height: 1.5;
    position: relative;
    margin: 0;
    padding: 10px;
}

.home_custom_h1__title .home_stretched_link {
    display: block;
    text-decoration: none !important;
    color: #121212 !important;
}

.home_stretched_link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.home_stretched_link>p:nth-child(2) {
    color: gray;
}

.home_custom_h1__title .home_intro_icon {
    color: #2294b5;
    vertical-align: middle;
    display: inline-flex;
}

.home_custom_h1__title:hover .home_intro_icon {
    -webkit-font-smoothing: antialiased;
    margin-left: 10px;
    transform: translate3d(30px, 0, 0);
}

.home_intro_icon {
    transition: transform .3s ease-out, color .3s ease-out
}

.home_custom_quotes__title {
    gap: 5px;
    align-items: flex-end;
    line-height: 1.5;
    padding: 15px;
}

.home_custom_quotes__title::before {
    background: #121212;
    content: "";
    display: inline-block;
    height: 1px;
    margin-right: 12px;
    width: 32px;
}

.home_custom_quotes__title {
    align-items: center;
}

.home_custom_quotes__title a {
    display: block;
    text-decoration: none !important;
    color: #121212 !important;
    font-weight: 700;
}

.home_custom_quotes__title a:hover {
    text-decoration: underline !important;
}

/* Home Info container */

.home_intro_strter_cont {
    background: black;
    /* background: white; */

}

.home_intro_card_info_img_cont_main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.home_intro_card_info_img_cont {
    margin: auto;
    width: 100%;
    /* height: 55vh; */
    border-radius: 5px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;

    overflow: hidden;

}

.home_intro_card_info_img_cont .home_intro_content-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 0.5;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.home_intro_card_info_img_cont:hover .home_intro_content-overlay {
    opacity: 1 !important;
}

.home_intro_card_info_img_cont:hover .home_intro_content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}


.home_intro_card_info_img_cont .image_bounces_left {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}


.home_intro_content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

@keyframes bounceInLeft {

    0%,
    60%,
    75%,
    90%,
    100% {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}


.home_intro_content-details h3 {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.1em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.home_intro_content-details p {
    color: #fff;
    font-size: 0.9em;
}

.home_inro_fadeIn-left {
    left: 20%;
}

.home_intro_services_cont_main {
    display: flex;
    justify-content: center;
    align-items: center;
}
.home_intro_services_cont {
    width: 90%;
    margin: auto;
}

.home_intro_services_cont > h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    color: white;
}

.home_intro_stretched_link {
    color: white;
    text-decoration: none;
    font-size: 4vw;

}
.home_intro_stretched_link:hover {
    color: white;
    text-decoration: none;
}
.home_intro_stretched_link > p{
    display: flex;
    justify-content: space-between;
}

.home_intro_stretched_link:hover .home_intro_icon_second {
    -webkit-font-smoothing: antialiased;
    margin-left: 10px;
    transform: translate3d(30px, 0, 0);
}

.home_intro_icon_second {
    transition: transform .3s ease-out, color .3s ease-out
}