.marketing_strategy_header_main > h2 {
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .marketing_strategy_card_main {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    min-height: 400px;
  }
  
  .marketing_strategy_card_main div:nth-child(1) {
    width: 150px;
    height: 150px;
  }
  
  .marketing_strategy_card_main div img {
    width: 100%;
    height: 100%;
  }
  
  .marketing_strategy_card_main div:nth-child(2) h4 {
    font-size: 16px;
  }
  
  .marketing_strategy_card_main div:nth-child(2) h2 {
    font-size: 30px;
    font-weight: 700;
  }
  