.industries-top-banner {
    height: 400px;
    /* border: 1px solid red; */
}

.industries-top-banner img {
    height: 400px;
    object-fit: cover;
}

.industries-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 150;
}

.industries-banner-content-child {
    padding: 5px;
}

.industries-banner-content-child > h1 {
    font-size: 50px;
    font-weight: 800;
    letter-spacing: 2.5px;
    line-height: 69px;
}

.industries-banner-content-child > p {
    font-size: 25px;
    letter-spacing: 2px;
}
.industires-banner-clip-img > div {
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: -1px;
    left: 0;
    z-index: 100;
    height: 200px;
    -webkit-clip-path: polygon(40% 0, 0 55%, 37% 100%, 100% 0, 100% 100%, 100% 100%, 20% 100%, 0 58%);
    -o-clip-path: polygon(40% 0, 0 55%, 37% 100%, 100% 0, 100% 100%, 100% 100%, 20% 100%, 0 58%);
    -ms-clip-path: polygon(40% 0, 0 55%, 37% 100%, 100% 0, 100% 100%, 100% 100%, 20% 100%, 0 58%);
    -moz-clip-path: polygon(40% 0, 0 55%, 37% 100%, 100% 0, 100% 100%, 100% 100%, 20% 100%, 0 58%);
    clip-path: polygon(40% 0, 0 55%, 37% 100%, 100% 0, 100% 100%, 100% 100%, 20% 100%, 0 58%);
}

@media (max-width: 992px) {
    .industries-banner-content-child > h1 {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 50px;
    }
    
    .industries-banner-content-child > p {
        font-size: 18px;
        letter-spacing: 2px;
    }
}