.contact_address_card_cont {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* min-height: 280px; */
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}

.contact_address_card_child_2 {
    bottom: 0;
    margin-top: 90px;
}

.contact_address_card_child_2 h3 {
    font-size: 18px;
}
.contact_address_link {
    color: #2294b5;
    text-decoration: none;
    font-weight: 700;
}
.contact_address_link:hover {
    color: #2294b5;
    text-decoration: none;
}




.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}

.contact-us-hover-effects:hover {
    box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}

.map-icon {
    position: absolute;
    width: 95%;
}
.contact-us-hover-effects i {
    width: 90px;
    height: 90px;
    text-align: center;
    border-radius: 50%;
    line-height: 90px;
    color: #2294b5;
}

.contact-us-hover-effects:hover i {
    color: #fff;
    transform: translateY(-80%);
    background: #2294b5;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    line-height: 90px;
    text-align: center;
    box-shadow: 0 2px 4px -2px lightgrey;
}

.contact-us-hover-effects i {
    transform: translateY(0);
    transition: 0.3s linear;
    font-size: 40px;
}





@keyframes bounceInLeft {

    0%,
    60%,
    75%,
    90%,
    100% {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}