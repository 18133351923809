

.project_timeline ul li {
  list-style-type: none;
  position: relative;
  width: 3px;
  margin: 0 auto;
  padding-top: 20px;
  background: #2294b5;
}
.project_timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 15px;
  height: 15px;
  z-index: 2;
  border-radius: 2px;
  background: #3c6fc1;
}
.project_timeline ul li div {
  position: relative;
  bottom: 0;
  width: 32vw;
}

.project_timeline ul li:nth-of-type(odd) > div {
  left: 45px;
}
.project_timeline ul li:nth-of-type(even) > div {
  left: -35vw;
}

.project_timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.project_timeline ul li:nth-of-type(odd) div {
  transform: translate3d(100px, -10px, 0) rotate(10deg);
}
.project_timeline ul li:nth-of-type(even) div {
  transform: translate3d(-100px, -10px, 0) rotate(10deg);
}
.project_timeline ul li.project-in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .project_timeline ul li:nth-of-type(even) > div {
    left: -289px;
  }
  
}
@media screen and (max-width: 600px) {
  .project_timeline ul li {
    margin-left: 0px;
  }
  .project_timeline ul li div {
    width: calc(100vw - 91px);
  }
  .project_timeline ul li:nth-of-type(even) > div {
    left: 30px;
  }
  .project_timeline ul li:nth-of-type(odd) > div {
    left: 30px;
  }
}