.project_details_banner_cont {
    /* background: url('https://evolvingweb.com/sites/default/files/styles/ewsite8_header/public/2023-01/GTL_banner_0.jpg.jpeg?itok=5fAaKoHZ') !important; */
    background-position: relative !important;
    background-repeat: no-repeat !important;
    background-size: cover;
    /* padding-top: 50px;
    padding-bottom: 50px; */
    height: 30vw;
    min-height: 400px;
    position: relative;
}

.project_details_banner_overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.project_details_banner_logo div {
    z-index: 10;
    max-width: 200px;
}

.project_details_banner_logo div img {
    height: auto;
    max-width: 100%;
}

.project_details_banner_details {}

.project_details_banner_details h1 {
    font-size: clamp(40px, 6vw, 80px);
    color: #fff;
}


.project_detail_overview_cont h6 {
    font-weight: 700;
    margin-bottom: 20px;
}

.project_detail_overview_cont p {
    font-weight: 400;
    line-height: 30px;
}

.project_detail_overview_img_cont {
    margin-top: 30px;
    margin-bottom: 45px;
}

.project_detail_overview_img_cont img {
    height: auto;
    max-width: 100%;

}

.project_details_contact_btn {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}





.project_details_desc_list>li {
    position: relative;
    margin-bottom: 10px;
  }
  
  .project_details_desc_list>li::before {
    -webkit-font-smoothing: antialiased;
    content: "";
    display: inline-block;
    font-family: FontAwesome, sans-serif;
    font-size: 16px;
    font-weight: 800;
    text-rendering: auto;
    top: 0px;
    color: #2294b5;
    left: -28px;
    position: absolute;
  }
  