.industry_higher_edu_design_desc_card_main {
    padding: 20px;
    text-align: center;
}

.industry_higher_edu_design_desc_card_main div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 200px;
    height: 200px;
    margin: auto;
}

.industry_higher_edu_design_desc_card_main div img {
    width: 100%;
}
.industry_higher_edu_design_desc_card_main p {
    font-size: 15px;
    font-weight: 300;
}


.industry_higher_edu_design_desc_card_main_second {
    padding: 20px;
    text-align: center;
}

.industry_higher_edu_design_desc_card_main_second>h3 {
    font-size: 18px;
    font-weight: 700;
}

.industry_higher_edu_design_desc_card_main_second>p {
    font-size: 15px;
    font-weight: 300;
}