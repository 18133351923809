.home_industry_intro_main {
    width: 100%;
}


.home_industry_intro_section_header {
    margin-bottom: 60px;
    position: relative;
    padding-bottom: 20px;
    /* border: 1px solid red; */
}

.home_industry_intro_heading {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}

.home_industry_intro_section_header::before {
    content: '';
    position: absolute;
    display: block;
    width: 80px;
    height: 5px;
    background: #2294b5;
    bottom: 0;
    left: calc(50% - 25px);
}

.home_industry_intro_section_header h4 {
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #9195a2;
}

.home_industry_intro_section_header p {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #9195a2;
}


.home_industry_hovicon.home_industry_effect_1 {
    -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
    -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
    transition: transform ease-out 0.1s, background 0.2s;
}

.home_industry_hovicon {
    display: inline-block;
    font-size: 45px;
    cursor: pointer;
    text-align: center;
    position: relative;
    text-decoration: none;
    z-index: 1;
    color: #fff;
}

.home_industry_work-porcess-box {
    width: 100%;
    height: 180px;
    border: 1px solid #2294b5;
}

.home_justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.home_industry_hovicon.home_industry_effect_1:hover {
    background: rgba(255, 255, 255, 0.05);
    -webkit-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -ms-transform: scale(0.93);
    transform: scale(0.93);
    color: #fff;
}

.home_industry_hovicon.home_industry_effect_1:hover:after {
    -webkit-animation: sonar-effect 1.3s ease-out 75ms infinite;
    -moz-animation: sonar-effect 1.3s ease-out 75ms infinite;
    animation: sonar-effect 1.3s ease-out 75ms infinite;
}



.home_industry_hovicon:before {
    /* speak: none; */
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
}

.home_industry_hovicon.home_industry_effect_1:after {
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    opacity: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.home_industry_hovicon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}


.home_industry_intro_logo {
    visibility: visible;
    -webkit-animation-iteration-count: 3;
    -moz-animation-iteration-count: 3;
    animation-iteration-count: 3;
}


@keyframes sonar-effect {
    0% {
        opacity: 0.3;
        box-shadow: 0 0 0 5px #2294b5, 0 0 10px 10px #2294b5, 0 0 0 10px #2294b5;
        transform: scale(1);
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 5px #2294b5, 0 0 10px 10px #2294b5, 0 0 0 10px #2294b5;
        transform: scale(1.1);
    }

    100% {
        box-shadow: 0 0 0 5px #2294b5, 0 0 10px 10px #2294b5, 0 0 0 10px #2294b5;
        transform: scale(1.2);
        opacity: 0;
    }
}