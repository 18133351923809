.contact_bottom_label_main h2 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.contact_bottom_label_second_main h2 {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}
.contact_bottom_label_desc {
  font-size: 14px;
  text-align: center;
}
/* blink on contact us btn  */

.contact_bottom_get_started_btn {
  position: relative;
  overflow: hidden;
  background: #2294b5;
  padding: 12px 30px 12px 30px;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.4s ease-out;
  min-width: 220px;
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  cursor: pointer;
  border: 1px solid white;
}

.contact_bottom_get_started_btn:hover {
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact_bottom_get_started_btn:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 20px;
  height: 2px;
  background: #fff;
  transition: all 0.33s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.contact_bottom_dot-line-left {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.33s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.contact_bottom_text {
  transition: all 0.33s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-size: 18px;
  margin-left: 10px;
  font-weight: 500;
}

.contact_bottom_block-right {
  content: "";
  position: absolute;
  width: 40px;
  height: 100%;
  background: #000;
  right: -40px;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%) scale(1);
  transition: all 0.33s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.contact_bottom_get_started_btn:hover {
  animation: contact_btn_blink 0.33s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes contact_btn_blink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.contact_bottom_get_started_btn:hover::before,
.contact_bottom_get_started_btn:hover .contact_bottom_dot-line-left {
  transform: translateY(-50%) scale(0);
  opacity: 0;
  left: -60px;
}

.contact_bottom_get_started_btn:hover::after,
.contact_bottom_get_started_btn:hover .contact_bottom_block-right {
  transform: translateY(-50%) scale(1);
  opacity: 1;
  right: -10px;
}

.contact_bottom_get_started_btn:hover .contact_bottom_text {
  transform: translateX(-20px);
}
