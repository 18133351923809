.getintouch-img-cont {
  background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  height: 70vh;
  width: 100%;
}

.required_star {
  font-size: 15px;
}

.required_star_error {
  font-size: 15px;
  color: red;
}

.getintouch-label {
  font-weight: 600;
  font-size: 14px;
}

.form_feedback_error_text {
  font-size: 10px;
  color: red;
}

.GetInTouch_input_field_cont {
  display: flex;
  width: 100%;
  gap: 20px;
}


.getintouch-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.getintouch-input-container.getintouch-input-container-with-textarea {
  display: block;
  align-items: center;
  width: 100%;
  position: relative;
}


.getintouch-input-icon {
  position: absolute;
  color: #999;
  font-size: 20px;
}

.getintouch-input {
  padding: 10px;
  border: 1px solid #787878;
  border-radius: 0px;
  width: 100%;
  font-size: 14px;
  padding-left: 40px;
  border-top: none;
  border-left: none;
  border-right: none;
}

.getintouch-input:focus {
  outline: none;
}


.getintouch-input:focus-visible {
  outline: none;
}


.getintouch-input.getintouch-input-without-icon {
  padding-left: 0px;
}


.getintouch-textarea {
  width: 100%;
  height: 25vh;
  padding: 10px;
  resize: vertical;
  border: 1px solid #787878;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.5;
}

.getintouch-textarea:focus {
  outline: none;
}


.getintouch-textarea:focus-visible {
  outline: none;
}



.getintouch_submit_btn {
  width: 100%;
  font-weight: 600;
  /* padding: 20px !important; */
}

.getintouch_social_cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  min-height: 11vh;
  background-color: #0d112c;
  padding-left: 10vh;
  padding-right: 10vh;
}

.getintouch_social_icon {
  border: 1px solid rgba(239, 239, 239, 0.241);
  background-color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  color: white;

}



@media only screen and (max-width: 768px) {
  .textarea {
    height: 150px;
  }
}

@media only screen and (max-width: 480px) {
  .textarea {
    height: 100px;
  }
}


@media only screen and (max-width: 560px) {
  .GetInTouch_input_field_cont {
    display: block;
    width: 100%;
  }

  .getintouch-input-last-name {
    margin-top: 10px;
  }
}



@media screen and (min-width: 751px) and (max-width: 991px) {
  .GetInTouch_input_field_cont {
    display: block;
    width: 100%;
  }

  .getintouch-input-last-name {
    margin-top: 10px;
  }

  .getintouch-input-icon {
    font-size: 20px;
  }
}






/* button css  */

.getintouch_submit_button_cont {
  display: flex;
  justify-content: flex-end;
}

.getintouch_submit_button {
  width: 250px;
  height: 50px;
  border: none;
  outline: none;
  background: #2f2f2f;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-align: center;
  /* box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4); */
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.getintouch-check-box {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  margin-left: -60px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.getintouch-check-box svg {
  width: 40px;
}

.getintouch-check-box svg path {
  stroke-width: 3;
  stroke: #fff;
  stroke-dasharray: 34;
  stroke-dashoffset: 34;
  stroke-linecap: round;
}

.getintouch-active {
  background: #2294b5;
  transition: 0.2s;
}

.getintouch-active .getintouch-check-box {
  right: 0;
  opacity: 1;
  transition: 1s;
}

.getintouch_submit_button p {
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.getintouch-active p {
  margin-right: 50px;
  transition: 1s;
}

.getintouch-active svg path {
  stroke-dashoffset: 0;
  transition: 0.3s;
  transition-delay: 0.3s;
}