.service_design_desc_header_main>h2 {
    font-size: 16px;
    text-transform: uppercase;
}

.service_design_desc_card_main {
    padding: 20px;
}

.service_design_desc_card_main h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.service_design_desc_card_main p {
    font-size: 15px;
    font-weight: 300;
}