/* Left  */

.service_banner_item_cont_main {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.service_banner_item_bg_cont {
  background-color: #f5f5f5;
  height: 100%;
  max-width: 100%;
  margin-left: 100px;
}

.service_banner_item_content_cont {
  width: 100vw !important;
  margin-left: -100px !important;
}

.service_banner_column {
  padding: 0 !important;
  padding-right: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_banner_column::after {
  content: "";
  clear: both;
  display: block;
}

.service_banner_img_cont {
  max-height: 600px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.service_banner_hover_cont .service_banner_img_cont img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  height: auto;
  max-width: 100%;
}

.service_banner_hover_cont .service_banner_img_cont:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.service_banner_desc_media__body {
  width: 60%;
}

.service_banner_desc_cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_banner_desc_media__title {
  font-size: 36px;
  font-weight: 700;
}

.service_banner_desc_media__text>h2 {
  font-size: 25px;
  font-weight: 500;
}

.service_banner_desc_media__text>h3 {
  font-size: 17px;
  font-weight: 600;
}

.service_banner_desc_media__text>p {
  font-size: 16px;
  font-weight: 400;
}

.service_banner_desc_media__text>ul {
  list-style: none;
}

.service_banner_desc_list>li {
  position: relative;
  margin-bottom: 10px;
}

.service_banner_desc_list>li::before {
  -webkit-font-smoothing: antialiased;
  content: "";
  display: inline-block;
  font-family: FontAwesome, sans-serif;
  font-size: 16px;
  font-weight: 800;
  text-rendering: auto;
  top: 0px;
  color: #2294b5;
  left: -28px;
  position: absolute;
}

/* Right  */
.service_banner_item_cont_main_right {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.service_banner_item_bg_cont_right {
  height: 100%;
  max-width: 100%;
  margin-right: 100px;
}

.service_banner_item_content_cont_right {
  width: 100vw !important;
  margin-right: -100px !important;
}

.service_banner_column_right {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_banner_column::after {
  content: "";
  clear: both;
  display: block;
}

.service_banner_img_cont {
  max-height: 600px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.service_banner_hover_cont .service_banner_img_cont img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  height: auto;
  max-width: 100%;
}

.service_banner_hover_cont .service_banner_img_cont:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.service_banner_desc_cont_right {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* media Queries */
@media only screen and (max-width: 320px) {
  .service_banner_item_bg_cont {
    background-color: #f5f5f5;
    height: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .service_banner_item_content_cont {
    width: 100vw !important;
    margin-left: -0px !important;
  }

  .service_banner_column {
    padding: 0 !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service_banner_desc_media__body {
    width: 90%;
    padding: 20px;
  }

  /* right */

  .service_banner_item_bg_cont_right {
    height: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .right_banner_flex {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .service_banner_item_content_cont_right {
    width: 100vw !important;
    margin-right: -0px !important;
  }
}


/* For Tab */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .service_banner_item_bg_cont {
    background-color: #f5f5f5;
    height: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .service_banner_item_content_cont {
    width: 100vw !important;
    margin-left: -0px !important;
  }

  .service_banner_column {
    padding: 0 !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service_banner_desc_media__body {
    width: 90%;
    padding: 20px;
  }

  /* right */

  .service_banner_item_bg_cont_right {
    height: 100%;
    max-width: 100%;
    margin-right: 0px;
  }
  .right_banner_flex {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .service_banner_item_content_cont_right {
    width: 100vw !important;
    margin-right: -0px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .service_banner_item_bg_cont {
    background-color: #f5f5f5;
    height: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .service_banner_item_content_cont {
    width: 100vw !important;
    margin-left: -0px !important;
  }

  .service_banner_column {
    padding: 0 !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service_banner_desc_media__body {
    width: 90%;
    padding: 20px;
  }

  /* right */

  .service_banner_item_bg_cont_right {
    height: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .service_banner_item_content_cont_right {
    width: 100vw !important;
    margin-right: -0px !important;
  }
  .right_banner_flex {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .service_banner_column_right {
    padding: 0 !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* For Small But Greater then tab */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .service_banner_item_bg_cont {
    background-color: #f5f5f5;
    height: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .service_banner_item_content_cont {
    width: 100vw !important;
    margin-left: -0px !important;
  }

  /* right */

  .service_banner_item_bg_cont_right {
    height: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .service_banner_item_content_cont_right {
    width: 100vw !important;
    margin-right: -0px !important;
  }
}

/* Little Laptop */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .service_banner_item_bg_cont {
    background-color: #f5f5f5;
    height: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .service_banner_item_content_cont {
    width: 100vw !important;
    margin-left: -0px !important;
  }

  /* right */

  .service_banner_item_bg_cont_right {
    height: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .service_banner_item_content_cont_right {
    width: 100vw !important;
    margin-right: -0px !important;
  }
}