.service_globel_container {
  background: #2294b5;
  width: fit-content;
  border-radius: 20px;
}

.service_globel_btn {
  position: relative;
  color: white;
  transition: all 0.3s;
  padding: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.service_globel_btn:focus {
    /* outline: none !important; */
    border: transparent !important;
    /* -webkit-box-shadow: none !important; */
    box-shadow: none !important;
}

.service_globel_btn span {
  transition: all 0.3s;
  transform: scale(1, 1);
  color: white;
}

.service_globel_btn::before,
.service_globel_btn::after {
  content: "";
  position: absolute;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.service_btn-four:hover span {
  transform: scale(1.2, 1.2);
  border-radius: 20px;
}

.service_btn-four::before {
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
  transform: scale(0.1, 0.1);
  border-radius: 20px;
}

.service_btn-four:hover::before {
  opacity: 1;
  transform: scale(1, 1);
  border-radius: 20px;
}

.service_btn-four::after {
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
}

.service_btn-four:hover::after {
  transform: scale(0, 0);
  opacity: 0;
  border-radius: 20px;
}
