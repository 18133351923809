.maintanance_info_card_header_text {
    font-size: 18px;
    font-weight: 600;
}


/* Audit info css  */
.maintanance_info_card_main {
    /* border: 1px solid red; */
    padding: 10px;
  }
  
  .maintanance_info_card_main div:nth-child(1) h4 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .maintanance_info_card_main div:nth-child(1) p {
    font-size: 14px;
    font-weight: 500;
  }


  .maintanance_info_desc_media__text > ul {
    list-style: none;
  }
  


  .maintanance_info_desc_list > li {
    position: relative;
    margin-bottom: 10px;
  }
  
  .maintanance_info_desc_list > li::before {
    -webkit-font-smoothing: antialiased;
    content: "";
    display: inline-block;
    font-family: FontAwesome, sans-serif;
    font-size: 16px;
    font-weight: 800;
    text-rendering: auto;
    top: 0px;
    color: #2294b5;
    left: -28px;
    position: absolute;
  }