.strategy_process_header_main > h2 {
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .strategy_process_card_main {
    padding: 20px;
  }
  .strategy_process_card_main h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .strategy_process_card_main p {
    font-size: 15px;
    font-weight: 300;
  }