.contact_banner_main_cont {
    /* background: #0d112c url('../../../assets/Images/General/about-banner.png') !important; */
    background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important; 
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}


.contact_heading_banner_text_cont {
    padding: 5px;
}

.contact_heading_banner_text_cont > h1 {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: 2.5px;
    line-height: 69px;
}

.contact_heading_banner_text_cont > p {
    font-size: 20px;
    letter-spacing: 2px;
}


 
@media (max-width: 992px) {
    .contact_heading_banner_text_cont > h1 {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 50px;
    }
    
    .contact_heading_banner_text_cont > p {
        font-size: 18px;
        letter-spacing: 2px;
    }
}