.globalbgcolor {
  background-color: black;
  color: #fff;
}

.capeark-footer {
  padding: 10vh 0 3vh 0;
}

.capeark-footer-cont .footer-row-logo {
  width: 160px;
  /* border: 1px solid red; */
  margin-bottom: 10px;
}

.footer-row-logo img {
  width: 100%;
  height: 100%;
}



.capeark-bottom-footer {
  width: 100%;
}
.capeark-bottom-footer-cont {
  height: 100%;
  background: linear-gradient(69deg, black, #1b1b1b, #171717, #1b1b1b, black);

}
.bottom-footer-terms-cont {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.bottom-footer_social_cont {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px;
  min-height: 70px;
}

.bottom-footer_social_cont .bottom-footer_social_icon_cont {
  background-color: rgba(255, 255, 255, 0.19);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  border: 1px solid rgba(239, 239, 239, 0.241);
  color: #fff;
}

.bottom-footer_social_cont .bottom-footer_social_icon_cont:hover {
  color: #2294b5;
  transition: all 0.3s ease-out;
  cursor: pointer;

}
.footer_link{
  text-decoration: none;
  color: #b3b3b3;
}

.footer_link:hover{
  text-decoration: none;
  color: #2294b5;
}


.footer-bottom-sublink-cont {
  /* border: 1px  solid red; */
  color: #b3b3b3;
  margin-left: 5px;
  font-size: 14px;
  line-height: 25px;
}
.footer-bottom-sublink-cont > p {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .footer-bottom-sublink-cont{
    text-align: center;
  }

  .footer-bottom-heading-cont{
    text-align: center;
  }
  
}

@media (max-width: 768px) {
  .bottom-footer-terms-cont {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 10px;
  }
  .bottom-footer_social_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 70px;
  }
  .capeark-footer-cont .footer-row-logo {
    width: 140px;
    margin: auto;
  }
  
}
  

/* media Queries */
@media only screen and (max-width: 320px) {
  .footer_bottom_flex {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}


/* For Tab */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .footer_bottom_flex {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer_bottom_flex {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
}