.job_details_content_sec_main {
}

.job_details_content_sec_main h6 {
    font-size: 15px;
    font-weight: 700;
}

.job_details_content_sec_main p {
    font-size: 14px;
    font-weight: 500;
}

.job_details_content_sec_main ul {
    font-size: 14px;
    font-weight: 400;
}
.job_details_content_sec_main ul li {
   margin-bottom: 5px;
}

.job_details_content_sec_apply_btn_main {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.job_details_content_sec_apply_btn_main div {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    background: #2294b5;
    padding: 10px 90px;
    cursor: pointer;
    border-radius: 10px;
}

.job_apply_input_lebal {
    font-size: 15px;
    font-weight: 600;
}
.job_apply_feedback_text {
    font-size: 12px !important;
}
.job_apply_feedback_p_text {
    color: #dc3545;
    margin-top: 5px;
}

.job_apply_input_box {
    border-radius: 5px !important;
 
}
.job_apply_input_box:hover {
    border-color: black !important;
}
.job_apply_input_box:focus {
    box-shadow: none !important;
}


.job_apply_application_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.job_apply_application_heading div h4 {
    font-size: 15px;
    font-weight: 600;
}

.job_apply_application_heading div h4 span{
    color:#888d94;
    font-size: 14px;
}

.job_apply_application_heading div:nth-child(2) button{
    color:#888d94;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #888d94;
    padding: 3px 15px;
}
.job_apply_application_exp_conf_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.job_apply_application_exp_conf_cont div {
    border: 1px solid#c2cddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 80px;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}
.job_apply_application_exp_conf_cont div:hover {
    background-color: #d3dbe7;
}
.job_apply_application_exp_conf_cont .job_apply_application_exp_conf_cont_active {
    background-color: #d3dbe7;
}

.job_apply_application_exp_conf_cont div:nth-child(1) {
    border-radius: 5px 0px 0px 5px;
}
.job_apply_application_exp_conf_cont div:nth-child(2) {
    border-radius: 0px 5px 5px 0px;
}



.job_apply_applicaton_exp_main {
    border-radius: 5px;
    background: #d3dbe7;
}
.job_apply_applicaton_exp_btn_cont {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.job_apply_applicaton_exp_btn_cont button {
    border: none;
    background: #2294b5;
    color: #fff;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    margin-right: 10px;
}

.job_apply_submit_btn {
    border: none;
    background: #2294b5;
    color: #fff;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
}