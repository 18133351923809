.project_single_effect_main_cont {
  width: 100%;
  height: 87vh;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #fff;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
}

.project_single_effect_main_cont .project_effect_image {
  width: 100%;
  height: 100%;
}

.project_single_effect_main_cont .project_single_effect_main_overlay {
  width: 100%;
  left: 0;
  right: 0;
  font-size: 40px;
  height: 0px;
  position: absolute;
  transition: 0.5s ease all;
  visibility: hidden;
  opacity: 0;
  bottom: 0px;
  padding: 60px;
  text-decoration: none;
}

.project_single_effect_main_cont:hover .project_single_effect_main_overlay {
  visibility: visible;
  height: 100%;
  opacity: 0.8;
  
}

.project_double_effect_main_cont {
  max-height: 600px;
  position: relative;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  border-radius: 5px;
}

.project_double_effect_main_cont .project_double_effect_image {
  width: 100%;
  height: 100%;
}

.project_double_effect_main_cont .project_single_effect_main_overlay {
  width: 100%;
  left: 0;
  right: 0;
  font-size: 40px;
  height: 0px;
  position: absolute;
  transition: 0.5s ease all;
  visibility: hidden;
  opacity: 0;
  bottom: 0px;
  text-decoration: none;
}

.project_double_effect_main_cont:hover .project_single_effect_main_overlay {
  visibility: visible;
  height: 100%;
  opacity: 0.8;
}

.project_all_detail_cont_main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  color: #fff;
}

.project_all_single_detail_cont_main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
}

.project_all_single_detail_cont_main > div {
  width: 100%;
  padding: 13px;
}

.project_all_desc_text {
  font-size: 24px;
  transform: translate(0px, 0px);
  opacity: 1;
  text-align: left;
  letter-spacing: 2.5px;
  line-height: 1.25;
}

.project_all_name_text {
  font-size: 56px;
}

.project_all_single_desc_text {
  font-size: 18px;
  transform: translate(0px, 0px);
  opacity: 1;
  text-align: left;
  letter-spacing: 2.5px;
  line-height: 1.25;
}

.project_all_single_name_text {
  font-size: 30px;
}

.project_all_mob_detail_cont_main {
  display: none;
}
.project_all_single_mob_detail_cont_main {
  display: none;
}

@media screen and (max-width: 1000px) {
  .project_all_mob_detail_cont_main {
    display: block;
  }

  .project_all_mob_desc_text {
    font-size: 16px;
    transform: translate(0px, 0px);
    opacity: 1;
    text-align: left;
    letter-spacing: 2.5px;
    line-height: 1.25;
  }

  .project_all_mob_name_text {
    font-size: 25px;
  }
  .project_all_single_mob_detail_cont_main {
    display: block;
  }

  .project_all_single_mob_desc_text {
    font-size: 12px;
    transform: translate(0px, 0px);
    opacity: 1;
    text-align: left;
    letter-spacing: 1px;
    line-height: 1.25;
  }

  .project_all_mob_single_name_text {
    font-size: 18px;
  }

  .project_single_effect_main_overlay {
    display: none;
  }
}
