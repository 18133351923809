.audit_strategy_header_main > h2 {
  font-size: 16px;
  text-transform: uppercase;
}

.audit_strategy_card_main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  min-height: 400px;
}

.audit_strategy_card_main div:nth-child(1) {
  width: 150px;
  height: 150px;
}

.audit_strategy_card_main div img {
  width: 100%;
  height: 100%;
}

.audit_strategy_card_main div:nth-child(2) h4 {
  font-size: 16px;
}

.audit_strategy_card_main div:nth-child(2) h2 {
  font-size: 30px;
  font-weight: 700;
}

/* Audit info css  */
.audit_info_card_main {
  /* border: 1px solid red; */
  padding: 10px;
}

.audit_info_card_main div:nth-child(1) h4 {
  font-size: 20px;
  font-weight: 700;
}

.audit_info_card_main div:nth-child(1) p {
  font-size: 14px;
  font-weight: 500;
}

.audit_info_desc_media__text > ul {
  list-style: none;
}

.audit_info_desc_list > li {
  position: relative;
  margin-bottom: 10px;
}

.audit_info_desc_list > li::before {
  -webkit-font-smoothing: antialiased;
  content: "";
  display: inline-block;
  font-family: FontAwesome, sans-serif;
  font-size: 16px;
  font-weight: 800;
  text-rendering: auto;
  top: 0px;
  color: #2294b5;
  left: -28px;
  position: absolute;
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
  .audit_strategy_card_main {
    margin-top: 50px !important;
  }
}
