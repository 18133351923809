.dev_tech_top_desc_card_main {
    padding: 20px;
}

.dev_tech_top_desc_card_main h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.dev_tech_top_desc_card_main p {
    font-size: 15px;
    font-weight: 300;
}