.dev_tech_banner_project_link {
    text-decoration: none;
    color: white;
    font-weight: 700;

}
.dev_tech_banner_project_link:hover {
    color: white;
    font-weight: 700;
    text-decoration: none;

}

.dev_tech_desc_header_main>h2 {
    font-size: 16px;
    text-transform: uppercase;
}

.dev_tech_desc_card_main {
    padding: 20px;
}

.dev_tech_desc_card_main h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.dev_tech_desc_card_main p {
    font-size: 15px;
    font-weight: 300;
}