.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.indicator {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border: 1px #666 solid;
    margin: 0;
}

.indicator.active {
    color: #fff;
    background: #666;
}

.home-owl-slider {
    position: relative;
    height: fit-content;
}

.home-owl-slider .home_slider_item {
    height: fit-content;
    background: rgba(0, 0, 0, 0.6) !important;
}

.home-owl-slider .home_slider_item .home_slider_pages {
    min-height: 90vh;
    max-height: 90vh !important;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.home-owl-slider .home_slider_item .home_slider_pages .home_slider_pages_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
}


.home-owl-slider .home_carousel_btn {
    opacity: 0 !important;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background-color: rgb(0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
    color: white !important;
    /* padding: 10px; */
    margin: 10px !important;
    border-radius: 2px;
}

.home-owl-slider:hover .home_carousel_btn {
    opacity: 1 !important;

}

.home-owl-slider .home_slider_item .home_slider_cover .home_slider_pages_overlay .home_slider_header_content .home_slider_line {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border: 9px solid #fff;
    -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
    clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
}

.home-owl-slider .active .home_slider_line {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    animation-delay: 0.3s;
}

.home-owl-slider .home_slider_item .home_slider_cover {
    padding: 75px 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.home-owl-slider .home_slider_item .home_slider_cover .home_slider_pages_overlay .home_slider_header_content {
    position: relative;
    padding: 56px;
    overflow: hidden;
}


.home-owl-slider .home_slider_item .home_slider_cover .home_slider_pages_overlay .home_slider_header_content h1 {
    font-size: 56px;
    font-weight: 600;
    margin: 5px 0 20px;
    word-spacing: 3px;
    color: #fff;
}


.home-owl-slider .active h1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.3s;
}


.home-owl-slider .home_slider_item .home_slider_cover .home_slider_pages_overlay .home_slider_header_content h4 {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #fff;
}

.home-owl-slider .active h4 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-delay: 0.3s;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}


.home-banner-clip-img {
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-bottom: -1px;
    height: 80px;
    z-index: 100;
    -webkit-clip-path: polygon(0 0, 50% 54%, 100% 0, 100% 100%, 80% 95%, 0 100%);
    -o-clip-path: polygon(0 0, 50% 54%, 100% 0, 100% 100%, 80% 95%, 0 100%);
    -ms-clip-path: polygon(0 0, 50% 54%, 100% 0, 100% 100%, 80% 95%, 0 100%);
    -moz-clip-path: polygon(0 0, 50% 54%, 100% 0, 100% 100%, 80% 95%, 0 100%);
    clip-path: polygon(0 0, 50% 54%, 100% 0, 100% 100%, 80% 100%, 0 100%);
}

@media (max-width: 600px) {

    .home-owl-slider .home_slider_item .home_slider_cover .home_slider_pages_overlay .home_slider_header_content h1 {
        font-size: 30px;
        font-weight: 600;
        margin: 5px 0 20px;
        word-spacing: 3px;
        color: #fff;
    }


    .home-owl-slider .active h1 {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-name: fadeInDown;
        animation-delay: 0.3s;
    }


    .home-owl-slider .home_slider_item .home_slider_cover .home_slider_pages_overlay .home_slider_header_content h4 {
        font-size: 16px;
        font-weight: 300;
        line-height: 36px;
        color: #fff;
    }

    .home-owl-slider .active h4 {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-name: fadeInUp;
        animation-delay: 0.3s;
    }

    .home-owl-slider .home_slider_item .home_slider_pages {
        min-height: 70vh;
        max-height: 70vh !important;

    }
}