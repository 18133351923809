
.image_bounces_left.active {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
    visibility: "visible";
    width: 100%;
}

.home_intro_animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.home-banner-clip-two {
    -webkit-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    -o-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    -ms-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    -moz-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 5%, 0 100%);
    transform: rotate(180deg);
    background: #000;
    /* position: absolute; */
    width: 100%;
    /* bottom: -15px; */
    margin-top: -0.4px;
    left: 0;
    height: 80px;
    z-index: 100;
}

.home-banner-clip-three {

    -webkit-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    -o-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    -ms-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    -moz-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 5%, 0 100%);
    clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 5%, 0 100%);

    background: #000;
    /* position: absolute; */
    width: 100%;
    margin-bottom: -0.4px;
    left: 0;
    height: 80px;
    z-index: 100;
}


.project-banner-clip-one {
    -webkit-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 20%, 0 100%);
    -o-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 20%, 0 100%);
    -ms-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 20%, 0 100%);
    -moz-clip-path: polygon(0 0, 0 0%, 100% 0, 100% 100%, 50% 20%, 0 100%);
    clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 20%, 0 100%);
    transform: rotate(180deg);
    background: #0d112c;
    width: 100%;
    margin-top: -0.4px;
    left: 0;
    height: 80px;
    z-index: 100;
}
