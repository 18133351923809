.blog_detail_hero_cont {
    background: #000428;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #004e92, #000428);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #004e92, #000428);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.blog_detail_hero_cont {}

.blog_detail_hero_cont .blog_detail_img_container {
    position: relative;
    width: 85%;
    margin-top: 25px;
    padding: 26.37% 0 0 30px;
}

.blog_detail_hero_cont .blog_detail_img_container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 140%;
    object-fit: cover;
    border-radius: 24px;
    filter: drop-shadow(0 24px 60px rgba(0, 0, 0, .25));
}

.blog_detail_hero_content_cont {
    margin-top: 100px !important;
}
 
.blog_detail_content_text h1 {
    font-size: 45px;
    line-height: 60px;
    letter-spacing: -1px;
    color: #fff;
    font-weight: 300;
}

.blog_detail_extra_content {
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    font-size: 13px;
    font-weight: 600;
}


.blog_detail_main_cont {
    margin-top: 200px;
    margin-bottom: 50px;
}

.wp-block-separator.is-style-dots {
    background: none !important;
    border: none;
    height: auto;
    line-height: 1;
    text-align: center;
}
.blog_detail_main_con_p {
    white-space: pre-line;
    line-height: 30px;
    font-size: 15px;
    font-weight: 300;
}
.wp-block-separator.is-style-dots:before {
    color: currentColor;
    content: "···";
    font-family: serif;
    font-size: 1.5em;
    letter-spacing: 2em;
    padding-left: 2em;
}


.blog_detail_main_share_cont {
    padding-top: 20px;
    padding-bottom: 20px;
}

.blog_detail_main_share_cont h2 {
    font-size: 18px;
    font-weight: 700;
}

.blog_detail_main_share_cont div {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.blog_detail_main_share_cont div a {
    color: #ced2d7;
}

.blog_detail_main_share_cont div a:nth-child(1):hover {
    color: #00acee;
}

.blog_detail_main_share_cont div a:nth-child(2):hover {
    color: #4064ad;
}


.blog_detail_main_share_cont div a:nth-child(3):hover {
    color: #4064ad;
}


.blog_detail_main_share_cont div a:nth-child(4):hover {
    color: #e32f5c;
}


@media only screen and (max-width: 1000px) {
    .blog_detail_main_cont {
        margin-top: 150px;
        margin-bottom: 50px;
    }
    
}

@media only screen and (max-width: 700px) {
    .blog_detail_main_cont {
        margin-top: 80px;
        margin-bottom: 50px;
    }

    .blog_detail_content_text h1 {
        font-size: 30px;
        line-height: unset;
        letter-spacing: -1px;
        color: #fff;
        font-weight: 300;
    }
    .blog_detail_hero_content_cont {
        margin-top: 40px !important;
        text-align: center;
        
    }
    
    
    
}