.blog_card_main {
    filter: drop-shadow(0 3px 30px rgba(16, 18, 21, .15));
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.blog_card_main a {
    text-decoration: none;
    height: 100%;
    display: block;
}

.blog_card_main .blog_card_top_img_wrapper {
    height: 250px;
}

.blog_card_main .blog_card_top_img_wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.blog_card_content_top_details {
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
    font-size: 13px;
    font-weight: 600;
}

.blog_card_content_wrapper_main h3 {
    font-size: 18px;
    color: black;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 30px;
}

.blog_card_content_wrapper_main p {
    font-size: 14px;
    color: rgb(52, 52, 52);
    display: flex;
    align-items: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog_card_content_readmore_cont {
    display: flex;
    margin-top: 10px;
}


.blog_card_content_chip_cont {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.blog_card_content_chip_cont span {
    border: 1px solid #bee7fd;
    padding: 2px 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    color: black;
}



/* Custom tab  */

.blog_custom_tab_list_cont {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 5px;
    /* padding-bottom: 5px; */

}

.blog_custom_tab_list_cont::-webkit-scrollbar {
    display: none;
}

.blog_custom_tab_list {
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    text-transform: capitalize;
}

.blog_custom_tab_list:hover {
    color: #2294b5;
}

.blog_custom_tab_list_active>div {
    color: #2294b5;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 20px;
}

.blog_custom_tab_list>div {
    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #bee7fd;
    border: 1px solid #bee7fd;
}

.blog_custom_tab_list.blog_custom_tab_list_active>div {
    background-color: #fff;
}

.blog_custom_tab_list>div:hover {
    padding: 5px 25px;
    border: 1px solid #bee7fd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;

}