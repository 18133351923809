/* .topbar-main {
} */

.top-navigation-wrap {
	width: 100%;
	padding: 3px;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	background: -webkit-linear-gradient(45deg, #005b73 7%, #07b5b5 6%, #000 9%, #207392 8%, #207392 11%, #1b1e21 10%, #1b1e21 13%, #2294b5 13%, #2294b5 15%, #1b1e21 14%);
	background: -webkit-linear-gradient(left, #005b73 7%, #000 6%, #000 9%, #207392 8%, #207392 11%, #1b1e21 10%, #1b1e21 13%, #2294b5 13%, #2294b5 15%, #1b1e21 14%);
	background: linear-gradient(45deg, #005b73 7%, #000 6%, #000 9%, #207392 8%, #207392 11%, #1b1e21 10%, #1b1e21 13%, #2294b5 13%, #2294b5 15%, #1b1e21 14%);
	box-shadow: 0 1px 1px #dcdcdc;
}



.top_nav_social_cont {
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 20px;
}

.top_nav_social_cont>a {
	color: white;
}

.top_nav_social_cont>a:hover {
	color: #2294b5;
	transition: all 0.3s ease-out;
	cursor: pointer;

}