.applynow-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}


.applynow-input {
  width: 100%;
  border: 1px solid #333e49;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 40px;
  padding: 10px;
  transition: border-color .2s ease-in;

}

.applynow-input:focus {
  outline: none;
}

.applynow-textarea {
  width: 100%;
  height: 25vh;
  padding: 10px;
  border: 1px solid #787878;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.5;
}

.applynow-textarea:focus {
  outline: none;
}


.applynow-textarea:focus-visible {
  outline: none;
}

.applynow_submit_btn {
  width: 100%;
  font-weight: 600;
  /* padding: 20px !important; */
}


.job_apply_file_upload_main {
  border: 1px solid #3a3939;
  border-style: dashed;
  border-radius: 5px;
  padding: 10px;
  background: #f4f4f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  cursor: pointer;
}
.job_apply_file_upload_main > p {
  font-size: 16px;
  color: gray;
  margin-bottom: 0px;
}

.job_apply_file_upload_main_active {
  border: 1px solid #51a5f1;
  border-style: dashed;
}



.applynow_social_cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  min-height: 11vh;
  background-color: #0d112c;
  padding-left: 10vh;
  padding-right: 10vh;
}

.applynow_social_icon {
  border: 1px solid rgba(239, 239, 239, 0.241);
  background-color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  color: white;

}

.toggle_contaner{
  border: 1px solid red;
  width: 100%;
}

.toggle_add_button {
  border: 1px solid red;
}

.toggle_add_button>button {
  border: transparent;
  border: 1px solid #333e49;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px;
  margin-left: 88%;
  width: 10vh;
}

@media only screen and (max-width: 768px) {
  .textarea {
    height: 150px;
  }
}

@media only screen and (max-width: 480px) {
  .textarea {
    height: 100px;
  }
}


@media only screen and (max-width: 560px) {
  .applynow_input_field_cont {
    display: block;
    width: 100%;
  }

  .applynow-input-last-name {
    margin-top: 10px;
  }
}



@media screen and (min-width: 751px) and (max-width: 991px) {
  .applynow_input_field_cont {
    display: block;
    width: 100%;
  }

  .applynow-input-last-name {
    margin-top: 10px;
  }

  .applynow-input-icon {
    font-size: 20px;
  }
}






/* button css  */

.applynow_submit_button_cont {
  display: flex;
  justify-content: flex-end;
}

.applynow_submit_button {
  width: 250px;
  height: 50px;
  border: none;
  outline: none;
  background: #2f2f2f;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.applynow-check-box {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  margin-left: -60px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applynow-check-box svg {
  width: 40px;
}

.applynow-check-box svg path {
  stroke-width: 3;
  stroke: #fff;
  stroke-dasharray: 34;
  stroke-dashoffset: 34;
  stroke-linecap: round;
}

.applynow-active {
  background: #2294b5;
  transition: 0.2s;
}

.applynow-active .applynow-check-box {
  right: 0;
  opacity: 1;
  transition: 1s;
}

.applynow_submit_button p {
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.applynow-active p {
  margin-right: 50px;
  transition: 1s;
}

.applynow-active svg path {
  stroke-dashoffset: 0;
  transition: 0.3s;
  transition-delay: 0.3s;
}