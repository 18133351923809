.project_header_main_cont {
    /* border: 1px solid red; */
    /* background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)); */
    /* background: #0d112c !important; */
    background: #0d112c url(../../assets/capeark_img/skyscraperBanner.png) !important;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    padding-top: 50px;
    padding-bottom: 50px;
}


.project-page-header-title-text {
    color: #121212;
    font-size: 56px;
    line-height: 60px;
    margin-top: 0.3em;
    padding-top: 20px;
    text-align: left;
    letter-spacing: 2.5px;
    line-height: 1.25;
}

.project-page-header-subtitle-text {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;

}




/* Custom tab  */
/* .project_custom_TabContext {
    padding: 10px;
    width: 100%;
    border: 1px solid red;
    overflow-x: auto;
} */

.project_custom_tab_list_cont {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 5px;
    /* padding-bottom: 5px; */
    /* border: 1px solid red; */

}

.project_custom_tab_list_cont::-webkit-scrollbar {
    display: none;
}

.project_custom_tab_list {
    font-weight: 500;
    font-size: 14px;
    color: #a1a1a1;
    /* border: 1px solid red; */
    cursor: pointer;
}

.project_custom_tab_list:hover {
    color: #2294b5;
}

.project_custom_tab_list_active {
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

}

.project_custom_tab_list>div {
    padding: 5px 20px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.project_custom_tab_list>div .custom_tab_list_tab_count {
    font-size: 8px;
    /* border: 1px solid green; */
    margin-left: 5px;
    margin-top: -10px;
}

/* .custom_tab_list_active > span {
    height: 4px;
    border-radius: 1px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: #0E374B;
} */