.careers_header_main_cont {
    background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    padding-top: 50px;
    padding-bottom: 50px;
}

.careers-page-header-title-text {
    font-size: 23px;
    margin: 0;
    text-transform: uppercase;
    word-wrap: break-word;
    line-height: 1.5;
    letter-spacing: 2.5px;
}

.careers-page-header-subtitle-text {
    font-size: clamp(50px, 7.5vw, 90px);
    line-height: 1.2;
    margin: 16px 0 0;
    text-shadow: none;

}

.carrer_apply_link {
    line-height: 2.3rem;
    letter-spacing: .13125rem;
    color: #fff;
    margin-right: 50px;
    text-decoration: none;
    background-color: transparent;
    display: flex;
}

.carrer_apply_link:hover {
    text-decoration: none;
}

.carrer_apply_link>p {
    font-size: 1.5rem;
    margin-right: 2px;

}

/* .careers-border-bottom {
    border-bottom: 0.01px solid #969393;
} */

.about-contanet-section {
    left: 0;
    border: 0;
    width: 100%;
    z-index: 1;
}


.circle {
    align-items: center;
    text-align: center;
    height: 40px;
    width: 40px;
    justify-content: center;
    cursor: pointer;
    animation: fadeIn 0.3s;
    opacity: 0.6;
    background: #2294b5;
    border-radius: 50px;
    margin-left: 2px;
}

.circle:hover {
    opacity: 1;
}


.designer-developer>text {
    text-decoration: none;
}




/* https://www.capitalnumbers.com/images/common/career-bnr-img.jpg */

.no_job_div_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.no_job_div_cont div {
    max-width: 250px;
}
.no_job_div_cont div img {
    width: 100%;
    height: auto;
}
.no_job_div_cont h4 {
    font-size: 16px;
    margin-top: 10px;
    color: gray;
}