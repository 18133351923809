/* #Navigation
================================================== */

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 10px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on .header-navbar-brand img {
  height: 40px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navigation-wrap {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  /* background: -webkit-linear-gradient(45deg, #1F618D 38%, #ffffff 37%);
    background: -webkit-linear-gradient(left, #1F618D 38%, #ffffff 37%);
    background: linear-gradient(45deg, #1F618D 38%, #ffffff 37%); */
  /* background-image: linear-gradient(to right, #000000, #000000, #fff, #fff, #fff); */
  box-shadow: -3px 15px 9px -4px rgba(0, 0, 0, 0.1);
}

.header-navbar-brand img {
  height: 50px;
  width: auto;
  display: block;
  filter: brightness(100%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-toggler {
  float: right;
  border: none !important;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.nav-main-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}

.nav-main-item:hover .nav-main-link {
  color: #2294b5 !important;
}

.nav-main-item.active .nav-main-link {
  color: #2294b5 !important;
}

.nav-main-link {
  position: relative;
  padding: 5px 0 !important;
  display: block;
  text-decoration: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-main-item {
  display: inline-block;
  position: relative;
  color: #2294b5;
  font-family: Poppins, sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.6px;
}

.nav-main-item::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: #2294b5;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  /* transition: all 200ms linear; */
}
.nav-main-item.active::after {
	transform: scaleX(1);
	transform-origin: bottom left;
  }
.nav-main-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* #Primary style
================================================== */

.nav-main-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  margin-top: 12px;
  transition: all 300ms linear;
  overflow: hidden;
  border: none !important;
}

.nav-main-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}

.nav-main-item .dropdown-menu .dropdown-item {
  font-size: 14px;
  margin-bottom: 5px;
  padding: 6px 20px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #2294b5 !important;
  color: #fff !important;
}

.nav_item_custom_class .dropdown-toggle::after {
  /* transform: rotate(-90deg);
	transition-duration: 0.4s; */
  display: none;
}

/* #Media
================================================== */

@media (max-width: 767px) {
  .navigation-wrap {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    -webkit-transition: all 0.3s ease-out;
    transition: all 1s ease-out;
    /* background: -webkit-linear-gradient(45deg, #000000 60%, #ffffff 37%);
		background: -webkit-linear-gradient(left, #000000 60%, #ffffff 37%);
		background: linear-gradient(45deg, #000000 62%, #ffffff 37%); */
    /* box-shadow: 0 1px 1px #dcdcdc; */
  }

  .nav_collapsed_box {
    border-radius: 0px;
    padding: 10px;
    background-color: transparent;
  }

  .nav-main-item:after {
    display: none;
  }

  .nav-main-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: 0;
    width: 11px;
    height: 2px;
    content: "";
    border: none;
    background-color: #000;
    /* vertical-align: 0; */
  }

  .nav_item_custom_class .dropdown-toggle::after {
    position: absolute;
    display: inline-block;
    top: 10px;
    left: -23px;
    width: 2px !important;
    height: 11px;
    content: "";
    background-color: #000;
    border: 0px !important;
    transition: all 200ms linear;
  }

  .nav_item_custom_class:hover .dropdown-toggle::after {
    transform: rotate(-90deg);
    opacity: 0;
  }

  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }

  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  .nav-main-item {
    margin: 10px;
  }
  .nav-main-item .dropdown-menu {
    margin-top: 5px;
    margin-bottom: 5px;
    opacity: 0;
    visibility: hidden;
    box-shadow: none;
    width: 100%;
    position: absolute !important;
  }

  .nav-main-item.show .dropdown-menu {
    opacity: 1;
    /* display: block; */
    width: 100%;
    visibility: visible;
    transform: translate3d(0, 0px, 0);
  }
}
