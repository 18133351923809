.author-container-main>div {
    background-color: #f2f7fb;
    flex-direction: column;
    width: 100%;
    border-radius: 15px;
    padding: 50px;
}

.author-name > a {
    font-size: 14px;
    color: black;
    text-decoration: none;
}

.author-address-cont {
    font-size: 14px;
    color: black;
    text-decoration: none;
}




.author-address-cont:hover {
    font-size: 14px;
    color: black;
    text-decoration: none;
}

