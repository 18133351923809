.industry_govt_design_desc_card_main {
    padding: 20px;
}

.industry_govt_design_desc_card_main h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.industry_govt_design_desc_card_main p {
    font-size: 15px;
    font-weight: 300;
}


.industry_govt_design_desc_card_main h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.industry_govt_design_desc_card_h6 {
    font-weight: 600;
}

.industry_govt_design_desc_card_main_ol {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}