.home_footer_owl_slider .home_footer_slider_item .home_footer_slider_cover {
    padding: 80px 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: 110px 110px;
    background-repeat: no-repeat;
}

/* footer content css  */
.home_footer_get-started-section {
    left: 0;
    border: 0;
    width: 100%;
    z-index: 1;
}

.home_footer_get_started_line {
    width: 150px;
    background: #2294b5;
    border: 2px solid #2294b5;
    margin: auto;
    border-radius: 10px;
}

.home_footer_get_started_btn {}


.home_footer_bg_cont_main {
    margin-top: -80px;
}

.home-footer-content-img-fluid {
    max-width: 100%;
    min-height: 500px;
    object-fit: cover;
}





/* blink on contact us btn  */

.home_footer_get_started_btn {
    position: relative;
    overflow: hidden;
    background: #2294b5;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.4s ease-out;
    width: 170px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    cursor: pointer;
}

.home_footer_get_started_btn:hover {
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home_footer_get_started_btn:before {
    content: '';
    position: absolute;
    left: 0px;
    width: 20px;
    height: 2px;
    background: #fff;
    transition: all .33s cubic-bezier(.68, -.55, .265, 1.55);
}

.home_footer_dot-line-left {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all .33s cubic-bezier(.68, -.55, .265, 1.55);
}

.home_footer_text {
    transition: all .33s cubic-bezier(.68, -.55, .265, 1.55);
}

.home_footer_block-right {
    content: '';
    position: absolute;
    width: 40px;
    height: 100%;
    background: #000;
    right: -40px;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%) scale(1);
    transition: all .33s cubic-bezier(.68, -.55, .265, 1.55);
}

.home_footer_get_started_btn:hover {
    animation: contact_btn_blink .33s cubic-bezier(0, 0, .2, 1);
}

@keyframes contact_btn_blink {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}

.home_footer_get_started_btn:hover::before,
.home_footer_get_started_btn:hover .home_footer_dot-line-left {
    transform: translateY(-50%) scale(0);
    opacity: 0;
    left: -60px;
}

.home_footer_get_started_btn:hover::after,
.home_footer_get_started_btn:hover .home_footer_block-right {
    transform: translateY(-50%) scale(1);
    opacity: 1;
    right: -10px;
}

.home_footer_get_started_btn:hover .home_footer_text {
    transform: translateX(-20px);
}