.industry_non_profit_design_desc_card_main {
    padding: 10px;
}

.industry_non_profit_design_desc_card_main h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.industry_non_profit_design_desc_card_main p {
    font-size: 15px;
    font-weight: 300;
}



/* list items  */


.industry_non_profit__desc_media__text>p {
    font-size: 15px;
    font-weight: 300;
}

.industry_non_profit__desc_media__text>ul {
    list-style: none;
}

.industry_non_profit_desc_list>li {
    position: relative;
    margin-bottom: 10px;
    font-size: 15px;
}

.industry_non_profit_desc_list>li::before {
    -webkit-font-smoothing: antialiased;
    content: "";
    display: inline-block;
    font-family: FontAwesome, sans-serif;
    font-size: 16px;
    font-weight: 800;
    text-rendering: auto;
    top: 0px;
    color: #2294b5;
    left: -28px;
    position: absolute;
}