.home_comp_del_info_main {
    padding-top: 50px;
    padding-bottom: 50px;
}

.home_comp_del_tech_img_cont {
}

.home_comp_del_tech_line {
  border: 2px solid #2294b5;
  width: 150px;
  color: #2294b5;
  background-color: #2294b5;
  border-radius: 10px;
}

.home__company_intro_text_head {
  font-size: 33px;
}
.home__company_intro_text_quotes {
  font-size: 15px;
  letter-spacing: 1px;
}