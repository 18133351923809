.service_section_tech_main .service_section_tech_head {
	margin-bottom: 30px;
}

.service_section_tech_main .service_section_tech_head h4 {
	position: relative;
	padding: 0;
	line-height: 1;
	letter-spacing: 0.3px;
	font-size: 34px;
	font-weight: 700;
	text-align: center;
	text-transform: none;
	margin-bottom: 30px;
}

.service_section_tech_main .service_section_tech_head h4::before {
	content: '';
	width: 70px;
	height: 3px;
	background: #2294b5;
	position: absolute;
	left: 0px;
	bottom: -20px;
	right: 0;
	margin: 0 auto;
}

.service_section_tech_main .service_section_tech_head p {
	color: #818181;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
}



/* Card Effect  */

/* section::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(#DA22FF, #9733EE);
	clip-path: circle(30% at right 70%);
}

section::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(#E55D87, #5FC3E4);
	clip-path: circle(20% at 10% 10%);
}  */

.service_section_tech_container {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	cursor: pointer;
	/* margin: 40px 0; */
}

.service_section_tech_container .service_section_tech_card {
	position: relative;
	width: 300px;
	min-height: 400px;
	/* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* backdrop-filter: blur(10px); */
}

.service_section_tech_container .service_section_tech_card .service_section_tech_content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: 0.5s;
}

.service_section_tech_container .service_section_tech_card:hover .service_section_tech_content {
	transform: translateY(-60%);
}

.service_section_tech_container .service_section_tech_card:hover .service_section_tech_content .service_section_tech_imgBx {
	border-radius: 50%;
	background-color: #fff;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.service_section_tech_container .service_section_tech_card .service_section_tech_content .service_section_tech_imgBx {
	position: relative;
	width: 120px;
	height: 120px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
}

.service_section_tech_container .service_section_tech_card .service_section_tech_content .service_section_tech_imgBx img {
	/* position: absolute; */
	top: 0;
	left: 0;
	width: 70%;
	height: 70%;
	object-fit: cover;
}

.service_section_tech_container .service_section_tech_card .service_section_tech_content .service_section_tech_contentBx h3 {
	/* color: #fff; */
	/* text-transform: uppercase; */
	letter-spacing: 1px;
	font-weight: 500;
	font-size: 18px;
	text-align: center;
	margin: 20px 0 10px;
	line-height: 1.1em;
}

.service_section_tech_container .service_section_tech_card .service_section_tech_content .service_section_tech_contentBx h3 span {
	font-size: 12px;
	font-weight: 300;
	text-transform: initial;
}

.service_section_tech_container .service_section_tech_card .service_section_tech_sci {
	position: absolute;
	bottom: 50px;
	display: block;
}

/* .service_section_tech_container .service_section_tech_card .sci li {
	list-style: none;
	margin: 0 10px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
}

.service_section_tech_container .service_section_tech_card:hover .sci li {
	transform: translateY(0px);
	opacity: 1;
}


.service_section_tech_container .service_section_tech_card .sci li a {
	font-size: 20px;
} */



/* Division part  */
.service_section_tech_container .service_section_tech_card .service_section_tech_sci p {
	list-style: none;
	margin: 0 10px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
	text-align: center;
}

.service_section_tech_container .service_section_tech_card:hover .service_section_tech_sci p {
	transform: translateY(0px);
	opacity: 1;
}