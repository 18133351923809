.service_header_main_cont {
    /* border: 1px solid red; */
    /* background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)); */
    /* background: #0d112c !important; */
    background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    padding-top: 50px;
    padding-bottom: 50px;
}

.service-page-header-title-text {
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
    word-wrap: break-word;
    line-height: 1.5;
    letter-spacing: 2.5px;
}

.service-page-header-subtitle-text {

    font-size: clamp(50px, 7.5vw, 90px);
    line-height: 1.2;
    margin: 16px 0 0;
    text-shadow: none;

}


.service_intro_text {
    color: #121212;
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
}



@media (min-width: 992px) {
    .service_intro_text {
        font-size: 40px;
        line-height: 69px;
        letter-spacing: 2.5px;
    }
}