.about_header_main_cont {

  background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about-page-header-title-text {
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
  word-wrap: break-word;
  line-height: 1.5;
  letter-spacing: 2.5px;
}

.about-page-header-subtitle-text {

  font-size: clamp(50px, 7.5vw, 90px);
  line-height: 1.2;
  margin: 16px 0 0;
  text-shadow: none;

}

.about_intro_text {
  color: #121212;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
}





.about-msg-bg {
  background-color: #ffff;
  background-image: radial-gradient(circle at 0.01px 0.5px, rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 8px 8px;

}

.about_upper_footer {
  background: url(https://d1ugv6dopk5bx0.cloudfront.net/s3fs-public/upper-footer-banner.webp) !important;
  background-repeat: no-repeat;
  background-position: relative;
  background-repeat: no-repeat;
  background-size: cover !important;
}

.about_upper_footer_content_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about_lets_contact_btn {
  display: inline-block;
  text-transform: uppercase;
  color: #2294b5;
  text-decoration: none;
  border: 2px solid;
  background: 0 0;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: .2s all;
  transition: .2s all;
  margin-top: 40px;
  border-radius: 50px;
}

.about_lets_contact_btn:hover {
  text-decoration: none;
  color: #2294b5;
}

.about_msg_banner_item_cont_main_right {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.about_msg_banner_item_bg_cont_right {
  height: 100%;
  max-width: 100%;
  margin-right: 100px;
  background-color: #f5f5f5;

}

.about_msg_banner_item_content_cont_right {
  width: 100vw !important;
  margin-right: -100px !important;
}

.about_msg_banner_column_right {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_msg_banner_column::after {
  content: "";
  clear: both;
  display: block;
}

.about_msg_banner_img_cont {
  max-height: 600px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.about_msg_banner_hover_cont .about_msg_banner_img_cont img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  height: auto;
  max-width: 100%;
}

.about_msg_banner_hover_cont .about_msg_banner_img_cont:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.about_msg_banner_desc_cont_right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hand-shake-icon svg {
  fill: #2294b5 !important;
  width: 90px;
}

.about_section_text_cont {
}
.about_section_text_cont h1 {
  font-size: 25px;
}


@media (min-width: 992px) {
  .about_intro_text {
    font-size: 40px;
    line-height: 69px;
    letter-spacing: 2.5px;
  }
}


