.job-opening_header_main_cont {
  background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.job-opening-page-header-title-text {
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
  word-wrap: break-word;
  line-height: 1.5;
  letter-spacing: 2.5px;
}

.job-opening-page-header-subtitle-text {

  font-size: clamp(12px);
  line-height: 1.2;
  margin: 16px 0 0;
  text-shadow: none;

}

.job-opening-icon {
  color: #2294b5;
  font-size: 3.2vh;
}

.hiring_main_cont {
  background: #0d112c url(https://www.capitalnumbers.com/images/common/career-bnr-img.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.job-opening-box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  min-height: 280px;
  box-shadow: 0 2px 5px -2px grey;
}

.jobopening_details_main {
  margin-top: 50px;
}

.jobopening_details_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.jobopening_details_content > h2 {
  color: #333e49;
  font-weight: 600;
  margin: 24px auto 12px;
  font-size: 24px;
  line-height: 32px;
}

.jobopening_details_content p {
  background-color: #f2f4f5;
  color: #636d77;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 50px;
}
.jobopening_details_content div {
  /* border: 1px solid red; */
  font-size: 13px;
  color: #636d77;
}


.jobopening_details_tab_cont {
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 99;
}




.jobopening_custom_tab_list_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-top: 1px solid #e1e6eb;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  justify-content: center;
  align-items: center;
 
}
.jobopening_custom_tab_list {
  font-weight: 500;
  font-size: 13px;
  color: #a1a1a1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-top: 7px
}
.jobopening_custom_tab_list:hover {
  color: #0E374B;
}

.jobopening_custom_tab_list_active {
  color: #0E374B;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.jobopening_custom_tab_list > div {
  padding: 5px 20px;
}
.jobopening_custom_tab_list > span{
  height: 2px;
  margin: auto;
  margin-top: 4px;
}
.jobopening_custom_tab_list_active > span {
  height: 2px;
  border-radius: 1px;
  bottom: 0;
  width: 80%;
  margin: auto;
  margin-top: 4px;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #0E374B;
}

.jobopening_details_job_details_main {
  background: #F8F8F8;
}

/* .jobopening_details_job_details_cont {
} */
.jobopening_details_footer_main {
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-top: 1px solid #e1e6eb;
}
.jobopening_details_footer_main a {
  text-decoration: none;
  color: black;
  font-weight: 800;
  font-size: 14px;
}
.jobopening_details_footer_main a:hover {
  text-decoration: none;
  color: black;
}