  .readmore_arrow_link {
      width: fit-content;
  }

  .readmore_arrow_link .readmore_arrow {
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.5s;
      overflow: hidden;
  }

  .readmore_arrow_link:hover .readmore_arrow {
      border: solid 1px black;
      border-radius: 50%;
  }

  .readmore_arrow:after {
      position: absolute;
      display: block;
      content: "";
      width: 15px;
      height: 15px;
      top: 2.06px;
      right: 10px;
      border-bottom: solid 2px;
      transform: translatex(4px);
  }

  .readmore_arrow:before {
      position: absolute;
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      border-top: solid 2px;
      border-left: solid 2px;
      top: 55%;
      right: -5px;
      transform-origin: 0% 0%;
      transform: rotatez(140deg);

  }

  .readmore_arrow_link:hover .readmore_arrow:before {
      animation: aniArrow01 1s cubic-bezier(0, 0.5, 1, 0.4) infinite 0.5s;
      top: 55%;
  }

  .readmore_arrow_link:hover .readmore_arrow:after {
      animation: aniArrow02 1s cubic-bezier(0, 0.5, 1, 0.4) infinite 0.5s;
      top: 1.5px;
  }


  @keyframes aniArrow01 {
      0% {
          transform: rotatez(135deg) translateY(34px) translateX(35px);
      }

      100% {
          transform: rotatez(140deg) translateY(-34.5px) translateX(-35px);
      }
  }


  @keyframes aniArrow02 {
      0% {
          transform: translateX(-38px);
          top: 1.5px;
      }

      100% {
          transform: translateX(47px);
          top: 1.5px;
      }
  }