.home_msg_main_cont {
    background: #0d112c url(../../../assets/capeark_img/skyscraperBanner.png) !important;
    background-position: 0 0 !important;
    background-repeat: no-repeat !important;
    border: 0.1px solid transparent;
}

.home_msg_main_content_cont p {
    font-size: 15px;
    letter-spacing: 0.5px;
}


/* Home Intro Block one  */



.home_intro_block_one_main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.home_intro_block_one_cont {
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11rem;
    padding-bottom: 11rem;

}

.home_intro_block_one_cont>div {
    padding-left: 50px;
    padding-right: 50px;
    color: #fff;

}


.home_intro_block_one_card_content>h4 {
    font-size: 16px;
    margin: 20px 0;
    text-transform: uppercase;
    font-weight: 800;
}

.home_intro_block_one_card_content>p {
    line-height: 1.5;
    font-size: 18px;
}


/* media Queries */
/* For Tab */
@media only screen and (min-width: 0px) and (max-width: 319px) {

    .home_intro_block_one_cont {
        padding-top: 4rem;
        padding-bottom: 4rem;

    }

    .home_intro_block_one_cont>div {
        padding-left: 0px;
        padding-right: 0px;

    }

}

@media only screen and (min-width: 320px) and (max-width: 480px) {

    .home_intro_block_one_cont {
        padding-top: 5rem;
        padding-bottom: 6rem;

    }

    .home_intro_block_one_cont>div {
        padding-left: 10px;
        padding-right: 10px;

    }

}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .home_intro_block_one_cont {
        padding-top: 5rem;
        padding-bottom: 6rem;

    }

    .home_intro_block_one_cont>div {
        padding-left: 10px;
        padding-right: 10px;

    }



}

/* For Small But Greater then tab */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .home_intro_block_one_cont {
        padding-top: 5rem;
        padding-bottom: 6rem;

    }

    .home_intro_block_one_cont>div {
        padding-left: 10px;
        padding-right: 10px;

    }

}

 